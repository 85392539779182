export const ContentString = {
  headerBanner: {
    mainText: 'Our resumes get you interviews',
    button: 'Browse our packages',
  },
  titleHeader: {
    title: 'Your {{capitalize | flagshipProduct}} Review',
  },
  letterHeader: {
    reviewer: {
      name: 'Captain Morgan',
      email: 'laura@topresume.com',
      role: 'Resume Expert, TopResume',
    },
    date: 'July 27, 2022',
    text: `Hi Jane,

    This free resume critique is meant to give you an honest, straightforward assessment and some suggestions to help in your job search. I'm able to offer insight into how you compare to other job seekers competing for the same positions because I personally review hundreds of resumes every month.`,
  },
  headerImperfect: {
    nearlyPerfect:
      "It looks like you've already purchased a professional resume from us. Your professional resume is ready to pass ATS, attract employers, and land your next role!",
    troubled:
      "This free resume critique is meant to give you an honest, straightforward assessment and some suggestions to help in your job search. <strong>Unfortunately, our ATS system had some trouble scanning the resume you uploaded - and if we can't see your resume, then employer's can't either.</strong> No need to worry though - with some help from a professional writing expert, you can still get the role you deserve!",
  },
  rawDataCall: {
    header: {
      normal: 'The raw data,',
      italic: 'pulled directly from your {{flagshipProduct}}',
    },
    description:
      "“Cynthia A. Brewer's experience appears to be concentrated in Administrative or Clerical / Admin, with exposure to Engineering / General Engineering. Cynthia A. Brewer's experience appears to be LOWER-TO-MID LEVEL, with about 3 years of experience, with no management experience.”",
  },
  doesLookLikeYou: {
    title: 'Does this look like you?',
    body: "The content of this section has been pulled directly from your {{flagshipProduct}} by an applicant tracking system that is used by many Fortune 500 companies. If this is not a proper representation of who you are as a candidate, we're here to help.",
  },
  describeCareer: {
    title: 'Does this properly describe the career you’re pursuing?',
    body: 'If not, check out real-life examples where we helped someone change their story.',
  },
  skillsetConvey: {
    title: 'Is this the skillset that you want to convey to employers?',
    body: "If not, email me! I'm here to help you land more interviews so you can get more job offers.",
  },
  trackingExplanation: {
    header: 'What the tracking systems think of you as an applicant',
    header2: 'Skills',
    text: 'Hiring Managers use applicant tracking systems (ATS) to automatically designate a Best Fit based on your experience level and the kinds of keywords spread throughout your {{flagshipProduct}}. The Applicant Tracking System (ATS) will also assign a weighted score to Key Skills & Competencies from your {{flagshipProduct}} to paint a picture of who you are as a potential employee.',
  },
  majorIssues: {
    header: 'Here are the major issues holding your {{flagshipProduct}} back:',
  },
  critiqueTextList: [
    [
      {
        text: 'Cut down your use of bullets.',
        variant: 'bold',
        color: 'orange',
      },
      {
        text: 'If you include too many, they quickly lose effectiveness because nothing stands out.',
      },
    ],
    [
      {
        text: 'Show the outcome of your efforts.',
        variant: 'bold',
        color: 'orange',
      },
      {
        text: 'Show the outcomes of your efforts. Your {{flagshipProduct}} focuses too much on what you’ve done, not what you’ve achieved.',
      },
    ],
    [
      {
        text: 'Your {{flagshipProduct}} does not make a strong visual impression.',
        variant: 'bold',
        color: 'orange',
      },
      {
        text: 'Your {{flagshipProduct}} should appear clean, uncluttered, and appealing to the eye.',
      },
    ],
  ],
  majorIssueExpert: {
    nearlyperfect: [
      {
        icon: 'hand',
        button: {
          text: 'upgradeLinkedInBtn',
          url: '/linkedin-profile-services',
          tUrl: 'linkedIn',
        },
        items: [
          {
            text: 'upgradeLinkedIn',
            variant: 'title',
          },
          {
            text: 'upgradeLinkedInText',
          },
        ],
      },
      {
        icon: 'face',
        button: {
          text: 'boostConfidenceBtn',
          url: '/interview-coaching',
          tUrl: 'interviewCoaching',
        },
        items: [
          {
            text: 'boostConfidence',
            variant: 'title',
          },
          {
            text: 'boostConfidenceText',
          },
        ],
      },
    ],
    normal: [
      {
        button: {
          text: 'getExpertBtn',
          url: '/{{flagshipProduct}}-writing',
          tUrl: 'writing',
        },
        items: [
          {
            text: 'areExperts',
            variant: 'bold',
          },
          {
            text: 'consider',
          },
        ],
      },
      {
        version: 'v3',
        button: {
          text: 'findNextJobUsBtn',
          url: '/new/job-search',
        },
        items: [
          {
            text: 'willSource',
            variant: 'bold',
          },
          {
            text: 'andPrepare',
          },
        ],
      },
    ],
    troubled: [
      {
        icon: 'scroll',
        button: {
          text: 'troubledConnectWriter',
          url: '/{{flagshipProduct}}-writing',
          tUrl: 'writing',
        },
        items: [
          {
            text: 'troubledTakeControl',
            variant: 'title',
          },
          {
            text: 'troubledTakeControlText',
          },
        ],
      },
    ],
  },
  resultsBanner: {
    line1: 'Our critiquers are always striving to improve!',
    line2: 'How helpful was Amy’s critique?',
    line3: 'Thanks for sharing your thoughts!',
    line4: 'Do you have any specific suggestions?',
    line5: 'Thanks again!',
    line6:
      ' Remember, you can reach out to your critiquer {{critiquer_name}} at {{critiquer_email}} with questions at any time.',
  },
  critiqueSections: {
    visualSection: {
      title: 'Visual Presentation and Organization',
      body: `Sometimes looks do matter. In the case of your resume, it's especially true. I found your design to be visually uneven. I'd like to see your document more polished, something more fitting of your experience level. Remember that your resume is your marketing tool. It's the first glimpse a potential employer has of you.

    However, your use of bullets is splendid! They help the employer zero in on important pieces of information, and also break up long sections of text. At the same time, you shouldn't have too many bullets that it makes your resume difficult to read!`,
    },
    resumeSection: {
      title: 'Resume Writing',
      body: `Your career summary is not as strong as it should be. This is a key piece to compel the hiring manager to keep reading. Flesh out your career summary to define you as a professional and cover those areas most relevant to the job that you seek.

    Based on how the resume is phrased, you could be perceived as a "doer," as opposed to an "achiever." A few too many of your job descriptions are task-based and not results-based. This means that they tell you what you did, instead of what you achieved. This is a common misstep for non-professional resume writers. To be effective and create excitement a good resume aids the hiring executive envisage you delivering similar achievements at his or her company. Here are some examples of task-based sentences in your resume:`,
    },
    digitalSection: {
      title: 'Digital Readiness',
      body: `Your resume is saved in PDF format. 19% of resumes look like yours. While this may make viewing consistent for recruiters, some older Applicant Tracking Systems (ATS) might not be able to correctly parse this. You might want to consider converting your document to a Microsoft Word file.

    98% of resumes are smaller than yours, so you might want to trim down pieces that make the file bigger. Sometimes, this is as simple as standardizing fonts for formatting.`,
    },
    scanSection: {
      title: 'Resume Scan Test Results',
      body: `93% of all Hiring Managers use resume scanning software called Applicant Tracking Systems (ATS) to filter candidates from the application pool. To illustrate how you stand up to the automation, I passed your resume through the very same software that Hiring Managers use to filter the real talent from the hundreds, sometimes thousands, of candidates that apply for a single open position.`,
    },
  },
  processExplanation: {
    header: {
      title: 'How does the TopResume process work?',
      description:
        'Check out the 2-minute video below to learn more about how TopResume’s resume-writing services work.',
    },
    button: 'View Resume Packages',
    last: 'Please email me with any concerns. We’re here to help you make a great first impression.',
  },
  infoboxProperCareer: {
    header: 'Does this properly describe the career you’re pursuing?',
    items: [
      {
        text: 'If not,',
      },
      {
        text: 'check out real-life examples',
        url: '/success-stories',
      },
      {
        text: 'where we helped someone change their story.',
      },
    ],
  },
  infoboxConveySkillset: {
    header: 'Is this the skillset that you want to convey to employers?',
    items: [
      {
        text: 'If not,',
      },
      {
        text: 'email me!',
        url: 'mailto:amy@topresume.com',
      },
      {
        text: "I'm here to help you land more interviews so you can get more job offers.",
      },
    ],
  },
  infoGrid: [
    {
      contentful_id: '',
      info: '',
      variant: 'default',
      name: 'Full Name',
      body: 'Cynthia A. Brewer',
    },
    {
      contentful_id: '',
      info: '',
      variant: 'default',
      name: 'Most Recent Employer',
      body: 'Exact Sciences',
    },
    {
      contentful_id: '',
      info: '',
      variant: 'default',
      name: 'Most Recent Professional Position',
      body: 'Machine Learning Scientist/Engineer',
    },
    {
      contentful_id: '',
      info: '',
      variant: 'default',
      name: 'Contact Info',
      body: '1 (781) 605-8836 worldwsx@gmail.com',
    },
    {
      contentful_id: '',
      name: 'Location',
      variant: 'error',
      info: "Couldn't find this",
      body: 'NOT FOUND',
    },
    {
      contentful_id: '',
      info: '',
      variant: 'default',
      name: 'Experience',
      body: '2 total years',
    },
    {
      contentful_id: '',
      info: '',
      variant: 'default',
      name: 'Most Recent Education',
      body: 'Johns Hopkins University',
    },
    {
      contentful_id: '',
      info: '',
      variant: 'default',
      name: 'Management Score',
      body: '0/100 See how this is calculated',
    },
  ],
  skillATSList: {
    title: 'Applicant Tracking System (ATS) Optimization',
    items: [
      {
        title: 'Top Keywords & Skills',
        items: [
          { text: 'Machine Learning', variant: 'active' },
          { text: 'Data Quality', variant: 'active' },
          { text: 'Best Practices', variant: 'active' },
          { text: 'Collection', variant: 'active' },
          { text: 'Data Collection', variant: 'active' },
        ],
      },
      {
        title: 'Other Core Competencies',
        items: [
          { text: 'Data Validation' },
          { text: 'Deployment' },
          { text: 'Engineer' },
          { text: 'Exploration' },
          { text: 'Mentor' },
          { text: 'Metrics' },
        ],
      },
      {
        title: 'Less Weighted Skills',
        items: [
          { text: 'Amazon' },
          { text: 'Elastic' },
          { text: 'Compute' },
          { text: 'Cloud' },
          { text: 'Decision Trees' },
          { text: 'Deep Learning' },
          { text: 'Ec2' },
          { text: 'Eda' },
          { text: 'Frameworks' },
          { text: 'Java' },
        ],
      },
    ],
  },
  nextSteps: {
    header: 'Your Next Steps',
    subheader: 'Team up with a TopResume writer and land more interviews.',
    items: [
      {
        header: 'Professional Growth',
        subheader:
          'A professionally written and keyword-optimized resume that sets you apart.',
        url: '/',
      },
      {
        header: 'Career Evolution',
        subheader:
          'A professionally written and keyword-optimized resume that sets you apart.',
        url: '/',
      },
      {
        header: 'Executive Priority',
        subheader:
          'A professionally written and keyword-optimized resume that sets you apart.',
        url: '/',
      },
    ],
  },
  successStories: [
    {
      title: 'Success Stories',
      subheader:
        'We’ve helped more than 600,000 professionals from every industry and background take control of their careers. You could be next.',
      items: [
        {
          text: 'See Our Success Stories',
          url: '/success-stories',
        },
      ],
    },
    {
      title: "We're Here To Help",
      subheader: 'Questions about the resume rewriting process?',
      items: [
        {
          text: 'Frequently Asked Questions',
          url: '/faq',
        },
        {
          text: 'Send Us An Email',
          url: '/contact',
        },
      ],
    },
  ],
  perfectAIInfo: {
    header: 'Did you know?',
    items: [
      '46% of recruiters would disqualify a candidate for generating their resumes with an AI tool.',
      '67% of hiring managers believe they can tell when they are reading an AI-modified resume.',
      'Only 5% of recruiters would use AI tools to write most of their own personal resume.',
    ],
  },
}
