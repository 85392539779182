import React, { useMemo } from 'react'
import { Button } from '@mui/material'
import { ActionLinkPost, Button as ButtonType } from 'types/page'
import { useCritiqueStyles } from './styles'
import { interpolateString } from '../../utils/interpolateString'
import useBrand from '../../hooks/useBrand'
import { useMixpanelCTAClickTracking } from '../../utils/mixpanel'
import useDiscountToken from '../../hooks/useDiscountToken'
import useTidyLink from '../../hooks/useTidyLink'
import { kebabCase } from 'lodash'
import { useBETelemetry } from '../../hooks/useTelemetry' // Assuming you're using backend telemetry

export const CritiqueButton: React.FC<ButtonType<ActionLinkPost>> = (props) => {
  const { classes, cx } = useCritiqueStyles()
  const brand = useBrand()
  const handleMpCTAClickTracking = useMixpanelCTAClickTracking()
  const tidyTheLink = useTidyLink()
  const discountToken = useDiscountToken() || ''
  const telemetry = useBETelemetry() // Initialize telemetry hook

  const interpolatedSlug = useMemo(
    () =>
      props?.action?.slug
        ? interpolateString(props?.action?.slug, {
            ...brand,
            discountToken,
          }).toLowerCase()
        : '',
    [props?.action?.slug, brand, discountToken]
  )

  const extractLabelFromContext = (buttonElement: any) => {
    // Use button text for specific phrases
    const buttonText = buttonElement.textContent.trim().toLowerCase()

    if (
      buttonText.includes('browse our packages') ||
      buttonText.includes('enhance my resume') ||
      buttonText.includes('get expert help')
    ) {
      return buttonElement.textContent.trim()
    }

    // For other buttons, find the nearest parent with specific class and extract the first <p> text
    const parentElement = buttonElement.closest('.css-dzttlj-contentfulBox')
    if (parentElement) {
      const labelElement = parentElement.querySelector('p')
      if (labelElement) {
        return labelElement.textContent.trim()
      }
    }

    // Fallback to button text if not found
    return buttonElement.textContent.trim()
  }

  const handleClick = (e: any) => {
    const label = extractLabelFromContext(e.currentTarget)

    // Track the event using telemetry
    telemetry.track({
      event: 'click_cta_button',
      properties: {
        label,
      },
    })

    handleMpCTAClickTracking(
      label,
      tidyTheLink(interpolatedSlug),
      props.mixpanelTracking
    )
  }

  return (
    <Button
      data-testid={kebabCase(`cta ${props.buttonText}`)}
      classes={{
        root: props.codeClasses?.join(' '),
      }}
      onAuxClick={handleClick}
      onClick={handleClick}
      className={cx({
        [classes.mainButtonSmall]: props.codeClasses?.includes('linkSmall'),
        [classes.marginTop8]: props.codeClasses?.includes('marginTop8'),
      })}
      {...(props?.action.slug && {
        href: `../${interpolatedSlug}`,
        target: '_blank',
      })}
    >
      {props.buttonText}
    </Button>
  )
}
